<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Internet Plan for {{ user.name }}</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="saveInternetPlan"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-show="!(selectedStation === 'all' && form.station_id === '')"
                  cols="12"
                >
                  <v-select
                    v-model="form.internet_plan_id"
                    :items="internetPlans"
                    item-text="name"
                    item-value="id"
                    label="Internet Plan"
                    :rules="[v => !!v || 'Internet Plan is required']"
                    :error="form.errors.has('internet_plan_id')"
                    :error-messages="form.errors.get('internet_plan_id')"
                    :loading="isInternetPlansLoading"
                    hint="This determines the user's internet speed and usage limits."
                    outlined
                    required
                  >
                    <template v-slot:selection="data">
                      {{ data.item.name }} ({{ formatValidity(data.item.validity_unit, data.item.validity) }})
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title> {{ data.item.name }} ({{ formatValidity(data.item.validity_unit, data.item.validity) }})</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <span
                    v-if="!isInternetPlansLoading && internetPlans.length === 0"
                  >
                    There are no any Internet Plans available. Please go to <router-link to="/internet-plans">Internet Plans</router-link> page and create one.
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import { mapGetters } from 'vuex'
import billingIntervalName from '@/mixins/billingIntervalName'

export default {
  mixins: [billingIntervalName],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      internet_plan_id: '',
      allow_null_phone_number: true,
    }),
    internetPlans: [],
    isInternetPlansLoading: false,
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0) {
        this.form.update(value)
        this.form.phone_number = this.user.phone_number
        this.form.account_number = this.user.customer.account_number
        this.form.internet_plan_id = this.user.customer.customer_plan ? this.user.customer.customer_plan.internet_plan_id : ''
        this.form.internet_connection_type = this.user.customer.internet_connection_type
        this.form.password = this.user.customer.router_auth_password
        this.form.station_id = this.user.customer.station_id
        this.form.billing_type = this.user.customer.billing_type
      }
    },
  },
  created() {
    // Fetch the internet plans when the component is created
    this.fetchInternetPlans()
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.internet_plan_id = ''
    },
    fetchInternetPlans() {
      this.isInternetPlansLoading = true
      axios.get('internet-plans', {
        params: {
          perPage: 'all',
          stationId: this.form.station_id,
        },
      })
        .then(response => {
          this.internetPlans = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isInternetPlansLoading = false
        })
    },
    saveInternetPlan() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form.put(`customers/${this.user.customer.id}`)
          .then(response => {
            this.$toast.success('Internet plan added successfully')
            this.$emit('close')
            this.$emit('customer-internet-plan-added', response.data)
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
