export default {
  data: () => ({
    internetConnectionTypes: [
      { text: 'PPPoE', value: 1 },
      { text: 'Static Ip', value: 3 },
      { text: 'Dynamic Ip', value: 2 },
      { text: 'Hotspot', value: 4 },
    ],
  }),
  filters: {
    formatInternetConnection(value, internetConnectionTypes) {
      const internetConnection = internetConnectionTypes.find(connection => connection.value === value)

      if (internetConnection) {
        return internetConnection.text
      }

      return 'Unknown Connection'
    },
  },
}
