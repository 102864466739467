import axios from 'axios'

export default {
  data() {
    return {
      isSendingPortalCredentialsMessage: false,
      showPortalCredentialsMessageDialog: false,
      portalCredentialsMessageDialogTitle: 'Send Portal Credentials Message',
      portalCredentialsMessageDialogMessage: 'Are you sure you want to send the portal credentials message?',
      portalCredentialsMessageDialogAgreeText: 'Send',
    }
  },
  methods: {
    confirmAndSendPortalCredentialsMessage(customerId) {
      this.sendPortalCredentialsMessage(customerId)
    },
    sendPortalCredentialsMessage(customerId) {
      this.isSendingPortalCredentialsMessage = true
      axios
        .post(`send-portal-credentials-message/${customerId}`)
        .then(() => {
          this.$toast.success('Portal credentials message sent successfully')
          this.details.customer.portal_credentials_notification_sent = true
        })
        .catch(error => {
          console.error(error)
          this.$toast.error(`Error sending portal credentials message: ${error.message}`)
        })
        .finally(() => {
          this.isSendingPortalCredentialsMessage = false
          this.showPortalCredentialsMessageDialog = false
        })
    },
    confirmAndSendPortalCredentialsMessagesToCustomers(customerIds) {
      this.sendPortalCredentialsMessagesToCustomers(customerIds)
    },
    sendPortalCredentialsMessagesToCustomers(customerIds) {
      this.isSendingPortalCredentialsMessage = true

      // Send a single request with all customer IDs
      axios
        .post('send-portal-credentials-messages', { customerIds })
        .then(() => {
          this.$toast.success('Portal credentials messages sent successfully')
        })
        .catch(error => {
          console.error(error)
          this.$toast.error(`Error sending portal credentials messages: ${error.message}`)
        })
        .finally(() => {
          this.isSendingPortalCredentialsMessage = false
          this.showPortalCredentialsMessageDialog = false
        })
    },
  },
}
