<template>
  <v-row
    v-if="user && Object.keys(user).length > 0"
    justify="center"
  >
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Expiry Date for {{ user.name }}</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="updateExpiryDate"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <date-time-picker
                    :label="'Start Date'"
                    :default-date-time="user.customer.customer_plan.start_date"
                    @close="showDatePicker = false"
                    @change="updateStartDate"
                  />
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    :label="'Expiry Date'"
                    :default-date-time="user.customer.customer_plan.end_date"
                    :min-date="new Date()"
                    @close="showDatePicker = false"
                    @change="updateEndDate"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    startDateMenu: false,
    form: new Form({
      start_date: '',
      end_date: '',
    }),
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0 && this.user.customer.customer_plan) {
        this.form.start_date = this.user.customer.customer_plan.start_date
        this.form.end_date = this.user.customer.customer_plan.end_date
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.start_date = ''
      this.form.end_date = ''
    },
    updateStartDate(value) {
      if (value) {
        this.form.start_date = value
      }
    },
    updateEndDate(value) {
      if (value) {
        this.form.end_date = value
      }
    },
    updateExpiryDate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`customer-plans/update-dates/${this.user.customer.customer_plan.id}`)
          .then(() => {
            this.$toast.success('Expiry date updated successfully')
            this.$emit('close')
            this.$emit('expiry-date-updated', { start_date: this.form.start_date, end_date: this.form.end_date })
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
