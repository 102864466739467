<template>
  <v-dialog
    v-model="internalDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="text-h5">
        Import Customers
      </v-card-title>
      <v-form
        ref="form"
        method="post"
        action="/"
        lazy-validation
        @submit.prevent="importCustomers"
      >
        <v-card-text>
          <p>To ensure a smooth import process, please download and use the provided template. The template outlines the correct format.</p>
          <v-btn
            class="mb-4"
            color="primary"
            small
            :disabled="isDownloadingTemplate"
            :loading="isDownloadingTemplate"
            @click="downloadTemplate"
          >
            Download Template
          </v-btn>
          <div>
            <v-list dense>
              <v-list-item>
                <v-list-item-title>
                  <strong>Required fields:</strong>
                  <div>username, name, account_number, phone_number,</div>
                  <div>internet_connection_type, internet_plan, expiry_date, station.</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>Optional fields:</strong>
                    <div>created_at, email, physical_address, debt, credits, password.</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <v-file-input
            v-model="form.file"
            show-size
            label="Click to select a file"
            class="mt-4"
            outlined
            dense
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></v-file-input>
          <div
            v-if="Object.keys(formErrors).length > 0"
            class="error-messages"
          >
            <ul>
              <li
                v-for="(errors, field) in formErrors"
                :key="field"
              >
                <strong>{{ field }}:</strong>
                <span class="red--text">{{ errors.join(', ') }}</span>
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :loading="form.busy"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDownloadingTemplate: false,
    form: new Form({
      file: '',
    }),
    formErrors: {},
  }),
  computed: {
    internalDialog: {
      get() {
        return this.value
      },
    },
  },
  methods: {
    downloadTemplate() {
      this.isDownloadingTemplate = true
      axios({
        method: 'GET',
        url: 'download-import-customers-template',
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']

        let filename = 'CustomersData.xlsx' // default filename

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        this.$toast.error('There was an error downloading the template')
      }).finally(() => {
        this.isDownloadingTemplate = false
      })
    },
    importCustomers() {
      this.form.busy = true
      this.formErrors = {}
      const formData = new FormData()
      formData.append('file', this.form.file)

      axios.post('import-customers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.$emit('imported')
        this.$toast.success('Imported successfully')
        this.close()
      }).catch(error => {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors
        } else {
          console.error('Import error:', error)
          this.$emit('import-failed', error)
          const errorMessage = error.response.data.error || error.response.data.message
          if (errorMessage) {
            this.$toast.error(`Import failed: ${errorMessage}`)
          }
        }
      }).finally(() => {
        this.form.busy = false
      })
    },
    close() {
      this.$emit('close', false)
      this.$refs.form.reset()
    },
  },
}
</script>

<style>
/* Optionally add custom styles to enhance the dialog */
</style>
